import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: theme.spacing() * 2,
    '&>*': {
      marginLeft: theme.spacing() * 2
    },
    backgroundColor: '#B4C1CC',
    height: 60
  }
}));

export function Header() {
  const cn = useStyles();

  return (
    <div className={cn.root}>
      <img
        width="120"
        // @ts-expect-error @types/node はインストールしてあるが、何故か型エラーになる
        src={require('../resources/logo.png')}
        alt="HackforPlay"
      />
      <Typography variant="h6">みまもり設定</Typography>
    </div>
  );
}
