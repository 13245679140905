import { Typography } from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { style } from 'typestyle';

const cn = {
  root: style({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 8,
    paddingTop: 48
  }),
  header: style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }),
  img: style({
    margin: 8
  })
};

export function Verified() {
  return (
    <div className={cn.root}>
      <div className={cn.header}>
        <img
          // @ts-expect-error @types/node はインストールしてあるが、何故か型エラーになる
          src={require('../resources/parental-conrol.png')}
          alt="スライム"
          className={cn.img}
        />
        <Typography variant="h4" gutterBottom>
          みまもり設定の登録が完了しました
        </Typography>
      </div>
      <Typography align="center">
        <br />
        <Link to="/">こちら</Link>
        から設定を変更できます
      </Typography>
    </div>
  );
}
